import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { GoChevronRight } from "react-icons/go";

const kautilyaColloquyData = [
  {
    panelDiscussion: [
      {
        id: 1,
        title: "Digital Governance",
        desc: `India, with its vision for an inclusive and prosperous future, strongly emphasizes digital governance. This policy vision aims to enhance government services through "Digital Connectivity" and "Data-Led Governance," which seek to bridge the digital divide and foster inclusivity. By leveraging digital technologies and promoting data-driven decision-making, the vision strives to create a transparent and accountable governance framework, ensuring equitable access to opportunities and empowering every citizen to thrive in the digital age.`,
        img: "./../images/colluquy/kc-colloquy-1.jpeg",
        contentOrder: true,
        cards: [
          {
            id: 1,
            pannelName: "Ms Saranya Gopinath",
            pannelDesignation:
              "Director, Government Affairs and Public Policy at Razorpay India",
          },
          {
            id: 2,
            pannelName: "Ms Sudeepta Veerapaneni",
            pannelDesignation:
              "Chief Innovation Officer & Partner, Deloitte South Asia",
          },
          {
            id: 3,
            pannelName: "Mr Vikram Patil",
            pannelDesignation:
              "Director, Digital Literacy & Livelihood Initiatives,Nasscom Foundation",
          },
          {
            id: 4,
            pannelName: "Shri Jayesh Ranjan",
            pannelDesignation:
              "IAS; Special Chief Secretary, Department of ITE&C, Department of Industries & Commerce, Govt. of Telangana",
          },
        ],
      },
      {
        id: 2,
        title: "Climate Change & ESG",
        desc: `The Climate Change and ESG panel will delve into key themes shaping our sustainable future, focusing on balancing economic growth and environmental responsibility. It will explore the evolving expectations around ESG (Environmental, Social, and Governance) practices and their impact on businesses and societies. The discussion will also address the complex relationship between climate action, ESG initiatives, and social equity, focusing on ensuring a just transition for all. Additionally, the panel will examine the crucial role of international trade in combating climate change, including the potential impact of carbon tariffs and other policy mechanisms that promote sustainable practices.`,
        img: "./../images/colluquy/kc-colloquy-2.jpeg",
        contentOrder: false,
        cards: [
          {
            id: 1,
            pannelName: "Mr. Alok Verma",
            pannelDesignation: "Head Corporate Strategy & ESG, Ashok Leyland",
          },
          {
            id: 2,
            pannelName: "Mr. Abhishek Sharma",
            pannelDesignation:
              "Deputy Vice President-Research,Sambodhi Research and Communications Pvt. Ltd.",
          },
          {
            id: 3,
            pannelName: "Shri A S Pardha Saradhi",
            pannelDesignation:
              "Executive Director (Board & Co.Affairs) & Company Secretary in NMDC Limited",
          },
        ],
      },
      {
        id: 3,
        title: "Navigating the Future of Finance",
        desc: `The panel will address the interconnected challenges and opportunities within India’s climate finance landscape. It will examine 
                 the global and domestic barriers that hinder climate finance, including insufficient international funding and institutional obstacles like risk assessment, credit allocation, and regulatory constraints. The discussion will focus on aligning global financial flows with the needs of developing economies while exploring how Indian financial institutions can better support sustainable initiatives. Additionally, the panel will assess the effectiveness of key government policies, such as FAME and PLI schemes, and propose ways to enhance their impact on achieving India’s climate goals. Together, these insights aim to create a roadmap 
                 for overcoming funding gaps and improving policy efficacy, ensuring a balanced approach to economic growth and environmental sustainability.`,
        img: "./../images/colluquy/kc-colloquy-3.jpeg",
        contentOrder: true,
        cards: [
          {
            id: 1,
            pannelName: "Dr. Rathin Roy",
            pannelDesignation:
              "Distinguished Fellow, Kautilya School of Public Policy; Former Managing Director, Research and Policy ODI",
          },
          {
            id: 2,
            pannelName: "Mr. Kaushal Mahan",
            pannelDesignation: "Senior Vice President, Chase India",
          },
          {
            id: 3,
            pannelName: "Ms. Sharmila Chavaly",
            pannelDesignation:
              "Retd IRAS; Independent Non-Executive Director, Genus Power Infrastructures Ltd.; Former Joint Secretary, Department of Economic Affairs, GoI",
          },
        ],
      },
      {
        id: 4,
        title: "Women In Public Policy",
        desc: `The panel on "Women in Policy" will delve into the complex relationship between policies and gender equality.
           Discussions will explore how policies can inadvertently perpetuate gender inequalities and, conversely,
            how they can be strategically crafted to challenge these biases.
            The panel will also examine the obstacles encountered in designing and implementing gender-sensitive policies and question whether
            the presence of women in policy making roles influences the gendered lens through which policies are formulated. Additionally,
             the panel will investigate the barriers hindering women's ascension to leadership positions
         within policy spheres and the types of support and mentorship that can facilitate their progress`,
        img: "./../images/colluquy/kc-colloquy-4.jpeg",
        contentOrder: false,
        cards: [
          {
            id: 1,
            pannelName: "Ms. Shanthala Veigas",
            pannelDesignation:
              "Associate Director, Head of Partner Relations & Communications, WE Hub, Govt of Telangana",
          },
          {
            id: 2,
            pannelName: "Dr. Shanta Thoutam",
            pannelDesignation:
              "Former Chief Innovation Officer, Government of Telangana",
          },
          {
            id: 3,
            pannelName: "Dr. Mujaheed Shaikh",
            pannelDesignation: "Professor, Hertie School",
          },
        ],
      },
    ],
  },
  {
    Research_Paper_Abstract_Submission_Guidelines: [
      "Abstracts should be submitted in English and should be at most 500 words.",
      "Font size should be 12-point Times New Roman and 1.5 Spacing.",
      "The abstract should clearly state the research objectives, methodology, key findings, and implications.",
      "Authors must adhere to the APA style when formatting the abstract.",
      "Submissions must be original work and not under consideration for publication elsewhere.",
      "Please note that plagiarism and the submission of AI-generated content will not be encouraged for research paper abstract submissions. We value originality and authentic contributions to our discourse.",
      "Themes: Digital Governance, Climate Change & ESG (Environmental, Social, and Governance), Women in Public Policy, and Navigating the Future of Finance.",
      "The abstract submission should mention the theme under which the submission is being made.",
      "Last Date for Abstract Submission: 15th July.",
      "The shortlisted abstract submissions will be notified and given 20 days to submit their full paper.",
      "Conference Registration Charges: Rs 450 for selected abstracts.",
      "The registered shortlisted paper presenters will receive a conference kit, lunch, and high tea coupons.",
      "The jury will award certificates for the best paper presentations, categorised by theme, and all the paper presenters will receive digital presentation certificates.",
    ],
  },
  {
    Organising_Committee: [
      "Aradhana Pandian – (Core Committee, MPP Cohort of 2023-25)",
      "Yvonna Tia Steele – (Core Committee, MPP Cohort of 2023-25)",
      "Lakshmanan S – (Head of Content team, MPP Cohort of 2023-25)",
      "Aarini Mishra – (Head of Marketing team, MPP Cohort of 2023-25)",
      "Subhash Gottumukkala – (Head of Operations and Logistics, MPP Cohort of 2023-25)",
      "Vivek Kalhan Reshi – (Head of Finance team, MPP Cohort of 2023-25)",
      "Oaishik Bhattacharya – (Head of Paper Presentations team, MPP Cohort of 2023-25)",
    ],
  },
];
const TextImgContent = ({ content }) => {
  return (
    <Container>
      <Row>
        <Col xs={12} md={8}>
          <p className="p-colluquy">{content.desc}</p>
          <Row>
            {(content.cards || []).map((card) => (
              <Col xs={12} md={6} key={card.id}>
                <div className="pannel_card">
                  <h4 className="pannel_h4">{card.pannelName}</h4>
                  <h6 className="pannel_designation">
                    {card.pannelDesignation}
                  </h6>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
        <Col xs={12} md={4}>
          <img
            src={content.img}
            alt="kc-colloquy-img"
            className="w-full h-auto"
          />
        </Col>
      </Row>
    </Container>
  );
};

const ImgTextContent = ({ content }) => {
  return (
    <Container>
      <Row>
        <Col xs={12} md={4}>
          <img
            src={content.img}
            alt="kc-colloquy-img"
            className="w-full h-auto"
          />
        </Col>
        <Col xs={12} md={8}>
          <p className="p-colluquy">{content.desc}</p>
          <Row>
            {(content.cards || []).map((card) => (
              <Col xs={12} md={6} key={card.id}>
                <div className="pannel_card">
                  <h4 className="pannel_h4">{card.pannelName}</h4>
                  <h6 className="pannel_designation">
                    {card.pannelDesignation}
                  </h6>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const ColloquyEvent = () => {
  const [activeTab, setActiveTab] = useState("about");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleScroll = (tab) => {
    document.getElementById(tab).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="kautilya-Colloquy-main-container">
      <div className="title-container">
        <h2 className="kc-title">Kautilya Colloquy</h2>
        <p className="kc-caption">Inclusive Policies for India</p>
      </div>

      <div className="kc-colloquy-tabs-container">
        <ul className="kc-colloquy-ul">
          <li
            className={`kc-colloquy-ul-li ${
              activeTab === "about" ? "active" : ""
            }`}
            onClick={() => handleScroll("about")}
            onMouseEnter={() => handleTabClick("about")}
            onMouseLeave={() => handleTabClick("about")}
          >
            About
          </li>
          <li
            className={`kc-colloquy-ul-li ${
              activeTab === "panel-discussion" ? "active" : ""
            }`}
            onClick={() => handleScroll("panel-discussion")}
            onMouseEnter={() => handleTabClick("panel-discussion")}
            onMouseLeave={() => handleTabClick("panel-discussion")}
          >
            Panel discussion
          </li>
          <li
            className={`kc-colloquy-ul-li ${
              activeTab === "research-paper-submission" ? "active" : ""
            }`}
            onClick={() => handleScroll("research-paper-submission")}
            onMouseEnter={() => handleTabClick("research-paper-submission")}
            onMouseLeave={() => handleTabClick("research-paper-submission")}
          >
            Research Paper Submission
          </li>
        </ul>
      </div>
      {/* <div className="link kc-section-container">
        <h5 className="mt-5 mb-0 pulse animation_text ">
          <a
            href=" https://youtube.com/live/-n5N4AyHr_Q?feature=share"
            target="_blank"
            className="text-primary "
          >
            Click here to view the broadcast
          </a>
        </h5>
      </div> */}
      <div id="about" className="kc-section-container">
        <div className="kc-section-content-container">
          <h2 className="section-headings">About</h2>
          <p>
            The Kautilya School of Public Policy at GITAM University, Hyderabad,
            is proud to announce the second annual Kautilya Colloquy, a
            student-led initiative, scheduled for September 21st, 2024. The
            Colloquy will feature research presentations and panel discussions
            centred around four subthemes: Digital Governance, Climate Change &
            ESG, Women in Public Policy, and Navigating the Future of Finance.
            During the research paper presentations, researchers and
            practitioners will share insights, addressing key policy challenges
            and proposing inclusive policy solutions. The panel discussions on
            these four subthemes will host experts, including renowned
            academicians, senior government officials, industry stalwarts, and
            policy practitioners. These panels will offer diverse perspectives
            and explore pathways for inclusive policies in India across digital
            governance, climate change, women in public policy, and the future
            of finance.
          </p>
          <h2 className="section-headings">Dean's message</h2>
          <p>
            Congratulations to the Master of Public Policy batch of 2023-25 for
            continuing the annual student-led Kautilya Colloquy tradition.
            Legacies are built when every cohort of students builds upon the
            edifice laid down by its predecessors.{" "}
          </p>
          <p>
            The choice of the theme of “Inclusive Policies for India” is indeed
            appropriate. Inclusivity is both an enduring civilizational value
            and a constitutional imperative for India. Examining the impact of
            the churn we witness in our everyday lives on such abiding
            principles is an excellent academic initiative with public policy
            implications.{" "}
          </p>
          <p>
            I have witnessed the time and effort put in by the students to
            organize this event. Hence, I am as keen as they are to see them
            achieve the goals they have set for themselves.
          </p>
          {/* <div>
            <a
              class="btn btn-success"
              href="./pdf/Collqouy single fold A4 Brochure.pdf"
              target="_blank"
            >
              <i class="fas fa-arrow-alt-circle-down" aria-hidden="true"></i>{" "}
              DOWNLOAD BROCHURE
            </a>
          </div> */}
        </div>
      </div>

      <div
        id="panel-discussion"
        className="kc-section-container panel-container"
      >
        <div className="kc-section-content-container panel-section-container">
          <h2 className="section-headings">Panel Discussion</h2>
          {kautilyaColloquyData[0].panelDiscussion.map((each) => {
            return (
              <React.Fragment key={each.id}>
                <div className="panel-discussion-title-container">
                  <GoChevronRight className="pointer-icon" />
                  <h3 className="panel-discussion-title">{each.title}</h3>
                </div>
                {each.contentOrder ? (
                  <TextImgContent content={each} />
                ) : (
                  <ImgTextContent content={each} />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>

      <div id="research-paper-submission" className="kc-section-container">
        <div className="kc-section-content-container">
          <h2 className="section-headings">
            Research Paper Submission Process
          </h2>
          <p>
            We invite researchers to submit abstracts for presentations. We
            welcome Advanced Research (projects with results and conclusions
            ready for discussion) and Research in Progress (ongoing projects
            with preliminary findings or where results are still being
            developed). Please include the following information with your
            abstract: names, designations (job titles), affiliations
            (institutions or organisations), email addresses, and contact phone
            numbers for all authors{" "}
            {/* <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdrGhLbylkseTgP58tAFHDDrpAxe_Zika2v1PTDcDgwppNlLg/viewform"
              className="anchor"
            >
              {" "}
              Click here for Abstract submission{" "}
            </a> */}
            . Kindly review the abstract submission guidelines before submitting
            the abstract.
          </p>

          <div className="panel-discussion-title-container">
            <GoChevronRight className="pointer-icon" />
            <h3 className="panel-discussion-title">
              Research Paper Abstract Submission Guidelines:
            </h3>
          </div>

          <ul className="kc-colloquy-ul-research">
            {kautilyaColloquyData[1].Research_Paper_Abstract_Submission_Guidelines.map(
              (each, index) => (
                <li key={index} className="kc-colloquy-ul-li-research">
                  {each}
                </li>
              )
            )}
            <li className="kc-colloquy-ul-li-research">
              If you have any queries regarding the research paper presentation,
              write to us at{" "}
              <a
                href="mailto:paper_kspp2024@kautilya.org.in"
                className="anchor-mail"
              >
                paper_kspp2024@kautilya.org.in
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* <div className="kc-section-container panel-container">
        <div className="kc-section-content-container panel-section-container">
          <h2 className="section-headings">
            Become a Kautilya Colloquy Delegate
          </h2>
          <p>
            Become a delegate at the Kautilya Colloquy and immerse yourself in a
            day of insightful panel discussions, cutting-edge research
            presentations, and valuable networking opportunities.
            <a
              href="https://gevents.gitam.edu/registration/MTQ2Mg"
              target="_blank"
              className="anchor"
            >
              Click here for Payment
            </a>
          </p>
        </div>
      </div> */}

      <div className="kc-section-container panel-container">
        <div className="kc-section-content-container">
          <h2 className="section-headings">Contact Us</h2>
          <ul>
            <li>
              In case of any queries or suggestions, please write to us at:{" "}
              <a
                href="mailto:queries_kspp2024@kautilya.org.in"
                className="anchor-mail"
              >
                queries_kspp2024@kautilya.org.in
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="kc-section-container">
        <div className="kc-section-content-container">
          <h2 className="section-headings">Organising Committee</h2>
          <ul className="kc-colloquy-ul-research">
            {kautilyaColloquyData[2].Organising_Committee.map((each, index) => (
              <li key={index} className="kc-colloquy-ul-li-research">
                {each}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ColloquyEvent;
