import { BrowserRouter as Router } from "react-router-dom";
import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.scss";
import "./assets/css/responsive.css";
import NavBar from "./components/Navbar";
import Footer from "./components/Common_footer";

import "./assets/css/responsive.css";
import "./assets/css/style.scss";
import SocialLinks from "./components/SocialLinks";
import BackToTopButton from "./components/Backtotop";
import Metatag from "./components/Metatag";
import Routers from "./Routers";

function App() {
  const location_path = window.location.pathname;
  return (
    <Router>
      <>
        {location_path == "/governance-contact" ? "" : <NavBar />}
        {/* <NavBar /> */}

        {location_path == "/governance-contact" ? "" : <Metatag />}
        {location_path == "/governance-contact" ||
        location_path == "/admission-process" ? (
          ""
        ) : (
          <SocialLinks />
        )}
        {location_path == "/governance-contact" ? "" : <BackToTopButton />}
        <Routers />

        {/* <Admission_enquiry /> */}
        {/* <Footer /> */}
        {location_path == "/governance-contact" ||
        location_path == "/admission-process" ? (
          ""
        ) : (
          <Footer />
        )}
      </>
    </Router>
  );
}

export default App;
