import { Container, Row, Col } from "react-bootstrap";
import Tabs_section from "../components/Tabs";
import { useState, useEffect } from "react";
import axios from "axios";
import TabSection from "../components/TabSection";
import VideoBackground from "../components/VideoBackground";
import TopRecruiters from "../components/topRecruters";
function AdmissionProcess() {
  const [admTabsdata, setAdmTabsdata] = useState([]);

  useEffect(() => {
    fetchAdmtabsdata();
  }, []);
  const fetchAdmtabsdata = async () => {
    try {
      const response = await axios.get(
        "https://guprojects.gitam.edu/kautilya-admin/api/fetch-mppadmprocess-data"
      ); // Replace with your actual API endpoint
      setAdmTabsdata(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // console.log("phd-admin-tabs-data", admTabsdata)

  return (
    <div className="admission-process">
      <Container>
        <Row>
          <Col className="mt-lg-5 mt-0">
            <h2 className="adm-pro-heading2 mt-md-3 mt-1 text-center mb-2">
              MPP Admission Process
            </h2>
          </Col>
        </Row>
      </Container>
      {/* <div> */}
      <VideoBackground />
      {/* </div> */}

      <div className=" mt-4 mb-4">
        <Container className="student_table mb-0">
          <Row>
            <Col xl={12}>
              <a
                className="btn btn-success"
                href="./pdf/Admissions Brochure 2025 Web.pdf"
                target="_blank"
              >
                <i className="fas fa-arrow-alt-circle-down"></i> DOWNLOAD
                BROCHURE
              </a>
            </Col>
            <Col xl={12}>
              <div className="table-responsive">
                <table className="table table-bordered table-striped">
                  <tbody>
                    <tr>
                      <th className="imp-table" colSpan="6">
                        Important Dates
                      </th>
                    </tr>
                    <tr>
                      <th className="imp-tab-sub"></th>
                      <th className="imp-tab-sub">
                        Last Date to Submit Application
                      </th>
                      {/* <th className="imp-tab-sub">Personal Interview Slots</th> */}
                      {/* <th className="imp-tab-sub">
                        Scholarship Application Review Date
                      </th> */}
                      <th className="imp-tab-sub">Admission Result Date</th>
                      <th className="imp-tab-sub">
                        Last Date to Pay Admission Fee
                      </th>
                    </tr>
                    <tr>
                      <td className="imp-tab-sub1">Cycle 01</td>
                      <td className="imp-tab-sub1">31-Dec-24</td>
                      {/* <td className="imp-tab-sub1">6th to 9th Jan</td> */}
                      {/* <td className="imp-tab-sub1">11-Jan-25</td> */}
                      <td className="imp-tab-sub1">15-Jan-25</td>
                      <td className="imp-tab-sub1">02-Feb-25</td>
                    </tr>
                    <tr>
                      <td className="imp-tab-sub1">Cycle 02</td>
                      <td className="imp-tab-sub1">28-Feb-25</td>
                      {/* <td className="imp-tab-sub1">3rd to 8th March</td> */}
                      {/* <td className="imp-tab-sub1">10-Mar-25</td> */}
                      <td className="imp-tab-sub1">12-Mar-25</td>
                      <td className="imp-tab-sub1">22-Mar-25</td>
                    </tr>
                    <tr>
                      <td className="imp-tab-sub1">Cycle 03</td>
                      <td className="imp-tab-sub1">30-Apr-25</td>
                      {/* <td className="imp-tab-sub1">1st to 8th May</td> */}
                      {/* <td className="imp-tab-sub1">10-May-25</td> */}
                      <td className="imp-tab-sub1">13-May-25</td>
                      <td className="imp-tab-sub1">23-May-25</td>
                    </tr>
                    <tr>
                      <td className="imp-tab-sub1">Cycle 04</td>
                      <td className="imp-tab-sub1">10-Jun-25</td>
                      {/* <td className="imp-tab-sub1">12th to 18th June</td> */}
                      {/* <td className="imp-tab-sub1">20-Jun-25</td> */}
                      <td className="imp-tab-sub1">16-Jun-25</td>
                      <td className="imp-tab-sub1">30-Jun-25</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <Col xs={12} md={12} lg={6}>
            <h6 className="text-primary mt-0 mb-3">
              <b> ABOUT KAUTILYA SCHOOL OF PUBLIC POLICY, INDIA </b>
            </h6>
            <p>
              Kautilya’s Vision: “Working to rebalance the role of Society,
              Government, & Business for a ​more equitable and sustainable
              future”.
            </p>
            <p>
              Kautilya School of Public Policy is one of the top public policy
              schools in India, offering the ​best public policy programs for
              those passionate about making a difference in the ​governance of
              our country. We are bringing a solid cohort of change-makers under
              the ​mentorship of professionals who taught at Harvard Kennedy
              School, Stanford School of ​Business, IIM, etc, & with
              industry-wide experience.
            </p>
            <p>
              Studying at Kautilya is your access to an aspirational career in
              public policy whether in the ​private or in public sector.
            </p>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <div className="">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/LV96T34EmWs"
                title="YouTube video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="bg-primary mt-5 mb-3">
        <Container>
          <Row>
            <Col xl={12}>
              <h3 className="text-white p-1 f-20">
                What is Public Policy? Major Subjects and It's Scope.
              </h3>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <Col xl={12}>
            <p>
              Public policy can be defined as a collection of principles and
              actions that guide governmental responses to various societal
              issues. Scope of Public policy in India indeed encompasses a
              ​broad range of crucial areas. Economic Development, Social
              Justice, Environmental Sustainability, National Security,
              International Relations are the key India seeks public policy
              ​professionals to work and contribute for a greater good.
            </p>
            <p>
              Public policy covers diverse subjects like policy analysis,
              economics, governance, law, technology, languages, business
              studies, international relations, research methods, social issues,
              and ​public administration. Students gain insights into policy
              development’s social, economic, and political contexts.
            </p>
          </Col>
        </Row>
      </Container>
      <div className="bg-primary ">
        <Container>
          <Row>
            <Col xl={12}>
              <h3 className="text-white p-1 f-20">Types of Public Policy?</h3>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <Col xl={12}>
            <p className="mt-3">
              Public policies can be categorized based on their focus, scope,
              and duration. Here are several key types of public policy:
              Distributive Policies, Redistributive Policies, Regulatory
              Policies, Constitutional Policies, and Foreign Policies.
            </p>
          </Col>
          <Col xs={12} md={12} lg={5} className="mb-2">
            <img
              src="./images/admission-process/pic.jpg"
              className="img-fluid"
              alt="Admission Process"
            />
          </Col>
          <Col xs={12} md={12} lg={7}>
            <div className="ap_p">
              <h6 className="text-primary mb-3">
                <b> ABOUT MASTER'S PROGRAM IN PUBLIC POLICY (MPP) </b>
              </h6>
              <p>
                MPP is a
                <b> 2-year, full-time, residential, 103-credit program. </b>
                <span className="bold me-1">
                  {" "}
                  Each year consists of three terms.
                </span>
                Core and elective courses in the first-year curriculum will
                expand your knowledge of Indian policymaking structures and
                approaches, followed by a 3-month internship.
              </p>
              <p>
                In your second year, you will deep dive into one of{" "}
                <b>five areas of specialization </b> through electives in policy
                domains.
              </p>
              <p>
                The program culminates in a Master's Thesis in term 6, based on
                a <b> Capstone Project (CAP)</b>, undertaken either
                independently with an organization or as part of a faculty-led
                group research project on a current policy issue.
              </p>
              <p>
                Overall, the program consists of 6 terms of 3 months each with
                24 courses at a minimum, an internship project, and 10 skill
                shops.
              </p>
              {/* <p>
                <span className="text-primary"> Eligibility:</span> Whether
                you're in the <b> final year </b> of your undergraduate degree
                or a graduate <b>working professional from any background </b>,
                you're welcome to apply.
              </p> */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className="section_bg mt-4 mb-4 pt-4 pb-4">
        <Container>
          <Row>
            <Col xl={12}>
              <TabSection tabsData={admTabsdata} />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section_bg mb-4 pt-4 pb-4">
        <h3 className="bg-dark text-white text-center p-1 pb-2 f-20">
          Top Recruiters
        </h3>

        <TopRecruiters />

        <Container>
          <Row>
            <Col xs={12} md={12} lg={6}>
              <h6 className="mb-4 mt-4">
                <b> CHOOSE YOUR PUBLIC POLICY CAREER: </b>
              </h6>
              <p>
                {/* Kautilya's program's nomination of academic rigor and on -
                ground experience will give you the ​confidence and creative
                thinking to assume leadership in any career of your choice. */}
                Kautilya's MPP program is renowned for its rigorous academics
                and practical experience. By combining theoretical knowledge
                with real-world application, the program equips students with
                the confidence and creative thinking necessary to excel in any
                career they choose.
              </p>
              <ul className="me-0">
                <li className="custom-list-item">
                  Public Service, including policy making and implementation,
                  government roles and politics.
                </li>
                <li className="custom-list-item">
                  Private Sector, including business consulting, policy
                  consulting for large corporations, policy ​analysis, think
                  tanks and private policy implementation organizations.
                </li>
                <li className="custom-list-item">
                  {" "}
                  Non-profit or Social Enterprises.
                </li>
                <li className="custom-list-item">
                  {" "}
                  Media & Communications, with a focus on public sector.
                </li>
                <li className="custom-list-item"> Research and Academia.</li>
              </ul>
            </Col>
            <Col xs={12} md={12} lg={6}>
              <img src="./images/admission-process/4.png" />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bg-dark mt-5">
        <Container>
          <Row>
            <Col xl={12}>
              <h3 className="text-white text-center text-capital p-1 mb-2 f-20">
                Alumni Speak
              </h3>
            </Col>
          </Row>
        </Container>
      </div>

      <Container fluid>
        <Row>
          <Col xs={12} md={4}>
            <img src="./images/admission-process/1.png" />
          </Col>
          <Col xs={12} md={4}>
            <img src="./images/admission-process/2.png" />
          </Col>
          <Col xs={12} md={4}>
            <img src="./images/admission-process/3.png" />
          </Col>
        </Row>
      </Container>

      <footer>
        <div className="bg-dark py-1">
          <Container fluid>
            <Row>
              <Col xl={12}>
                <h5 className="text-white text-center">Contact Us</h5>
                <p className="text-white  text-center">
                  <i className="fa fa-map-marker me-2"></i>
                  GITAM (Deemed to be) UNIVERSITY Rudraram, Patancheru mandal
                  Hyderabad-502329 Telangana, INDIA.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bg-primary py-1 text-center text-white">
          <span>
            <i className="fa fa-phone me-2"></i>
            9100850851
          </span>
          <br />
          <span>
            <i className="fa fa-envelope me-2"></i>
            admissions@kautilya.org.in
          </span>
        </div>
      </footer>
    </div>
  );
}

export default AdmissionProcess;
