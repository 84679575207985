import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const VideoBackground = () => {
  return (
    <div className="video-container">
      {/* Video Element */}
      <video autoPlay loop muted playsInline className="videoStyle">
        <source src="./videos/kautilya_gitam.mp4" type="video/mp4" />
        <source src="./videos/kautilya_gitam.webm" type="video/webm" />
      </video>
    </div>
  );
};

export default VideoBackground;
