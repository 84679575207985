import React from 'react';
import {  Container, Row, Col } from 'react-bootstrap';
const Unhrc = () => {
    

    return (
        <>
           <section className="capstone mb-4">
            <Container>
                <Row>
                    <Col md={12} >
                       <h5 className='mt-4'>MoU with UNHRC</h5>
                    </Col>
                    <Col md={12}>
                    <img 
                    src="./../images/partners/partner_banner2.jpg" 
                    alt="partners" 
                    className="w-100 d-none d-md-block"                   
                />

              
                <img 
                    src="./../images/partners/partner_banner_mobile.jpg" 
                    alt="partners" 
                    className="w-100 d-md-none d-block" 
                   
                />
                    <p className='mt-4'>
                    Kautilya signed an MoU with #UNHCR to build impactful connections and advance our efforts in addressing global displacement challenges. Looking to make this collaboration a step towards developing innovative solutions together.
                    </p>
                  
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="capstone">
            <Container>
                <Row>
                    <Col md={12} >
                    </Col>
                    </Row>
                    </Container>
                    </section>

        </>
    );
}

export default Unhrc;
