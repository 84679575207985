import React from "react";
import Marquee from "react-fast-marquee";
import { Container, Row, Col } from "react-bootstrap";

const TopRecruiters = () => {
  const data = [
    { id: 1, src: "./images/admission-process/logo/1.png" },
    { id: 2, src: "./images/admission-process/logo/2.png" },
    { id: 3, src: "./images/admission-process/logo/3.png" },
    { id: 4, src: "./images/admission-process/logo/4.png" },
    { id: 5, src: "./images/admission-process/logo/5.png" },
    { id: 6, src: "./images/admission-process/logo/6.png" },
    { id: 7, src: "./images/admission-process/logo/7.png" },
  ];

  return (
    <Container fluid className="my-4 mb-4">
      <Row>
        <Col>
          <Marquee pauseOnHover speed={100}>
            {" "}
            {/* Adjust speed here */}
            {data.map((item) => (
              <div key={item.id} className="mx-3" style={{ width: "250px" }}>
                <img
                  src={item.src}
                  alt={`Recruiter-${item.id}`}
                  className="img-fluid"
                />
              </div>
            ))}
          </Marquee>
        </Col>
      </Row>
    </Container>
  );
};

export default TopRecruiters;
