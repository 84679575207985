import React, { useState, useEffect } from "react";
import kautilya from "../kautilya";
import { useLocation, useNavigate } from "react-router-dom";

const AllSlugs = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const missedPage = location.pathname;
  if (missedPage === "/kautilya-blog/" || missedPage === "/kautilya-blog") {
    navigate(`/blogs`);
  }
  const missedPageTitle = missedPage.split("/")[1];
  const [issue, setIssues] = useState([]);
  const [capstone, setCapstone] = useState([]);
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    fetchIssue();
    fetchcapstone();
    fetchblog();
  }, []);

  const fetchIssue = () => {
    kautilya.get("/issues-lists").then((res) => {
      const issueSlugs = res.data.data.map((each) => {
        return each.slug;
      });
      setIssues(issueSlugs);
    });
  };

  const fetchcapstone = () => {
    kautilya.get("/cp-lists").then((res) => {
      const capstoneSlugs = res.data.data.map((each) => {
        return each.slug;
      });
      setCapstone(capstoneSlugs);
    });
  };

  const fetchblog = () => {
    kautilya.get("/blog-lists").then((res) => {
      const blogsSlugs = res.data.data.map((each) => {
        return each.slug;
      });
      setBlog(blogsSlugs);
    });
  };

  useEffect(() => {
    if (issue?.includes(missedPageTitle)) {
      navigate(`/issue-brief/${missedPageTitle}`);
    } else if (capstone?.includes(missedPageTitle)) {
      navigate(`/capstone-project/${missedPageTitle}`);
    } else if (blog?.includes(missedPageTitle)) {
      navigate(`/blogs/${missedPageTitle}`);
    }
  }, [issue, capstone, blog, missedPage, navigate]);

  return <div className="all-slugs">Searching for the Page</div>;
};

export default AllSlugs;
