import { useEffect, useState } from "react";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";

import EventSlider from "../components/EventSlider";
import NewsSlider from "../components/newsSlider";
function Events() {
  const [eventsSliderData, setEventsSliderData] = useState([]);

  useEffect(() => {
    fetcheventsSliderData();
  }, []);
  const fetcheventsSliderData = async () => {
    try {
      const response = await axios.get(
        "https://guprojects.gitam.edu/kautilya-admin/api/fetch-resources-data"
      ); // Replace with your actual API endpoint
      setEventsSliderData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //   console.log("eventsSliderData", eventsSliderData);

  const events = eventsSliderData?.filter((each) => each.category === "Events");
  const policySeries = eventsSliderData?.filter(
    (each) => each.category === "Policy Series"
  );
  const colloquy = eventsSliderData?.filter(
    (each) => each.category === "Colloquy"
  );
  console.log("colloquy", colloquy);
  const new_updates = eventsSliderData?.filter(
    (each) => each.category === "News & Updates"
  );

  return (
    <div className="event_slider_width">
      <section>
        <Container>
          <Row>
            <Col xl={12} className="mt-5">
              <h1 className="events-headings">Events</h1>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              {/* <EventSlider sliderdata={slider} /> */}

              <EventSlider sliderdata={events} />
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col xl={12}>
              <h1 className="events-headings">Colloquy event</h1>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <EventSlider sliderdata={colloquy} />
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col xl={12}>
              <h1 className="events-headings">Let's Talk Policy Series</h1>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <EventSlider sliderdata={policySeries} />
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col xl={12}>
              <h1 className="events-headings">News & Updates</h1>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <NewsSlider sliderdata={new_updates} />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
export default Events;
